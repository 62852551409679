import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { usePageOutageContext } from 'cb-wallet-data/stores/PageOutage/PageOutageProvider';
import Image from 'next/image';
import Link from 'next/link';
import { useToggler } from '@cbhq/cds-common';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { TextBody } from '@cbhq/cds-web/typography';
import { HomebaseModal } from ':dapp/components/HomebaseModal/HomebaseModal';
import { InfiniteScrollingCarousel } from ':dapp/components/InfiniteScrollingCarousel/InfiniteScrollingCarousel';
import { withFeatureEnabled } from ':dapp/components/withFeatureEnabled/withFeatureEnabled';
import { OCSCommunitiesSection } from '../components/OCSCommunitiesSection';
import { OCSEarnBadgesSection } from '../components/OCSEarnBadgesSection';
import { OCSLeaderboard } from '../components/OCSLeaderboard/OCSLeaderboard';
import { OCSOutage } from '../components/OCSOutage';
import { OCSPageWrapper } from '../components/OCSPageWrapper';
import { OCSSectionHeader } from '../components/OCSSectionHeader';
import { OCSSpinWheel } from '../components/OCSSpinWheel';
import { OCSSpinWheelBanner } from '../components/OCSSpinWheelBanner';
import { OCSGamePass } from '../components/OCSSummerPass';
const messages = defineMessages({
  ocsPrizesSectionHeaderLink: {
    defaultMessage: 'Redeem prizes',
    description: 'link for prizes onchain section header for onchain summer'
  },
  ocsTopReferrersSectionHeaderLink: {
    defaultMessage: 'Refer friends',
    description: 'link for top referrers onchain section header for onchain summer'
  },
  spinWheelTitle: {
    defaultMessage: 'Spin the wheel',
    description: 'Spin the wheel modal title'
  },
  shareSummerPass: {
    defaultMessage: 'Refer friends, earn points',
    description: 'header for refer friends'
  },
  shareSummerPassShort: {
    defaultMessage: 'Refer friends',
    description: 'header for refer friends for mobile'
  },
  ocsPrizesTitle: {
    defaultMessage: 'Prizes',
    description: 'Prizes section!'
  },
  ocsPrizesSubtitle: {
    defaultMessage: 'Redeem your points for free or discounted prizes',
    description: 'Prizes section subtitle!'
  }
});
const coverFit = {
  objectFit: 'cover' as const
};
type RoundedImagePlaceholderType = {
  src?: string;
};
export function RoundedImagePlaceholder({
  src
}: RoundedImagePlaceholderType) {
  return <div className={"d1ptw0ob"}>
      <Image src={src ?? 'https://go.wallet.coinbase.com/static/placeholderocs.png'} fill style={coverFit} alt="" />
      <Box testID="placeholder-test-fixture" />
    </div>;
}

// component is temporarily disabled
// by request so that it is a non-blocking feature
// for release of ocs
export function OCSShopSection() {
  const shopIsEnabled = useIsFeatureEnabled('ocs_shop_tab');
  const {
    formatMessage
  } = useIntl();
  if (!shopIsEnabled) {
    return null;
  }
  return <VStack testID="ocs-shop-section-prizes-carousel">
      <OCSSectionHeader title={formatMessage(messages.ocsPrizesTitle)} subtitle={formatMessage(messages.ocsPrizesSubtitle)} spacingTop={4} rightContent={<Link href="/">
            <TextBody as="span" color="primary" noWrap>
              {formatMessage(messages.ocsPrizesSectionHeaderLink)}
            </TextBody>
          </Link>} />
      <InfiniteScrollingCarousel>
        <RoundedImagePlaceholder />
        <RoundedImagePlaceholder />
        <RoundedImagePlaceholder />
        <RoundedImagePlaceholder />
        <RoundedImagePlaceholder />
      </InfiniteScrollingCarousel>
    </VStack>;
}
function OCSProgressPage() {
  const {
    formatMessage
  } = useIntl();
  const [visible, {
    toggleOff,
    toggleOn
  }] = useToggler(false);
  const communitiesEnabled = useIsFeatureEnabled('ocs_communities');
  const {
    gamificationApiIssue,
    exploreApiIssue
  } = usePageOutageContext();

  /* istanbul ignore next */
  const spinWheelModal = useMemo(() => {
    if (!visible) return null;
    return <HomebaseModal title={formatMessage(messages.spinWheelTitle)} visible={visible} onClose={toggleOff} testID="spin-wheel-modal" contentOverflow="auto">
        <OCSSpinWheel />
      </HomebaseModal>;
  }, [visible, toggleOff, formatMessage]);
  if (gamificationApiIssue && exploreApiIssue) {
    return <OCSOutage />;
  }
  return <VStack width="100%" testID="ocs-tab-progress">
      <OCSPageWrapper>
        <VStack gap={4} spacingTop={2}>
          <OCSGamePass spinWheelToggle={toggleOn} />
          <OCSSpinWheelBanner toggleOn={toggleOn} />
          {spinWheelModal}

          <OCSEarnBadgesSection />
          {/* <OCSShopSection /> */}
          {communitiesEnabled && <OCSCommunitiesSection />}
          <OCSLeaderboard />
        </VStack>
      </OCSPageWrapper>
    </VStack>;
}
export default withFeatureEnabled(OCSProgressPage, 'ocs_progress_tab');

require("./index.page.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./index.page.tsx");